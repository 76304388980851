@import './utils';

$black: #333;
$white: #FFF;
$disabled-gray: rgba(0, 0, 0, 0.38);
$gray: rgba(0, 0, 0, 0.54);
$gray-2: rgba(0, 0, 0, 0.15);
$gray-3: rgba(0, 0, 0, 0.3);
$success: #090;
$error: #C00;
$warning: #FFA000;
$info: #0065A3;
$alert-dialog-background: #01579B;
$gray-light-2: rgba(0, 0, 0, 0.12);
